var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AcaoItem',{attrs:{"titulo":"Classificação","color":_vm.color,"historico":_vm.historico.historicosClassificacao},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p',[_vm._v(" Classifica o processo de acordo com as regras estabelecidas no edital. ")]),(_vm.processo.tipoProcesso.usaNotaEnem && _vm.processo.podeFazerAcoes)?_c('div',[_c('v-form',{ref:"parametrosClassificaoForm"},[(
            _vm.processo.tipoProcesso.usaNotaEnem &&
            _vm.processo.tipoProcesso.codigo !== 'TEE'
          )?_c('div',{ref:"notasDeCorteEnemDiv"},[_c('p',[_vm._v(" Preencha os valores de nota de corte. Os candidatos com nota inferior a nota de corte serão eliminados. ")]),_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-auto-numeric',{ref:"notaDeCorteLinguagens",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Linguagens *","name":"notaDeCorteLinguagens","outlined":"","validate-on-blur":""},model:{value:(_vm.classificacaoParams.notaDeCorteLinguagens),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteLinguagens", _vm._n($$v))},expression:"classificacaoParams.notaDeCorteLinguagens"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-auto-numeric',{ref:"notaDeCorteCienciasHumanas",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Ciências Humanas *","name":"notaDeCorteCienciasHumanas","outlined":"","validate-on-blur":""},model:{value:(
                  _vm.classificacaoParams.notaDeCorteCienciasHumanas
                ),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteCienciasHumanas", _vm._n($$v))},expression:"\n                  classificacaoParams.notaDeCorteCienciasHumanas\n                "}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-auto-numeric',{ref:"notaDeCorteCienciasNatureza",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Ciências da Natureza *","name":"notaDeCorteCienciasNatureza","outlined":"","validate-on-blur":""},model:{value:(
                  _vm.classificacaoParams.notaDeCorteCienciasNatureza
                ),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteCienciasNatureza", _vm._n($$v))},expression:"\n                  classificacaoParams.notaDeCorteCienciasNatureza\n                "}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-auto-numeric',{ref:"notaDeCorteMatematica",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Matemática *","name":"notaDeCorteMatematica","outlined":"","validate-on-blur":""},model:{value:(_vm.classificacaoParams.notaDeCorteMatematica),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteMatematica", _vm._n($$v))},expression:"classificacaoParams.notaDeCorteMatematica"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-auto-numeric',{ref:"notaDeCorteRedacao",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Redação *","name":"notaDeCorteRedacao","outlined":"","validate-on-blur":""},model:{value:(_vm.classificacaoParams.notaDeCorteRedacao),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteRedacao", _vm._n($$v))},expression:"classificacaoParams.notaDeCorteRedacao"}})],1)],1)],1):_vm._e(),(['TEE', 'MUD'].includes(_vm.processo.tipoProcesso.codigo))?_c('div',{ref:"notaDeCorteTotalDiv"},[(
              _vm.processo.tipoProcesso.codigo === 'TEE' &&
              _vm.classificacaoParams.situacaoInscricaoAClassificar == 'ATV'
            )?_c('v-auto-numeric',{ref:"notaDeCorteTotal",attrs:{"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Nota Total *","name":"notaDeCorteTotal","outlined":"","validate-on-blur":""},model:{value:(_vm.classificacaoParams.notaDeCorteTotal),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "notaDeCorteTotal", _vm._n($$v))},expression:"classificacaoParams.notaDeCorteTotal"}}):_vm._e(),_c('v-select',{attrs:{"dense":"","items":[
              { nome: 'Ativos', codigo: 'ATV' },
              { nome: 'Aptos', codigo: 'APT' } ],"item-text":"nome","item-value":"codigo","label":"Situação da inscrição para classificar","name":"situacaoInscricaoAClassificar","outlined":""},model:{value:(_vm.classificacaoParams.situacaoInscricaoAClassificar),callback:function ($$v) {_vm.$set(_vm.classificacaoParams, "situacaoInscricaoAClassificar", $$v)},expression:"classificacaoParams.situacaoInscricaoAClassificar"}})],1):_vm._e()])],1):_vm._e()]},proxy:true},{key:"botao",fn:function(){return [_c('v-btn',{staticClass:"mt-1",attrs:{"disabled":!_vm.processo.podeFazerAcoes,"color":"primary","outlined":""},on:{"click":_vm.classificar}},[_vm._v(" Classificar ")]),_c('v-btn',{staticClass:"mt-1 ml-2",attrs:{"disabled":!_vm.historico.historicosClassificacao,"color":"accent","outlined":""},on:{"click":_vm.visualizarClassificacao}},[_vm._v(" Visualizar ")])]},proxy:true},{key:"extra",fn:function(){return [_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialogResultado),callback:function ($$v) {_vm.dialogResultado=$$v},expression:"dialogResultado"}},[(_vm.dialogResultado)?_c('v-card',{key:"resultado"},[_c('v-card-title',{staticClass:"primary white--text text-h6 justify-center mb-2"},[_vm._v(" Resultado Classificação ")]),_c('v-card-text',{staticClass:"overflow-y-auto"},[_c('v-alert',{attrs:{"type":_vm.resultado.tipo,"text":"","outlined":"","dense":""}},[_vm._v(" "+_vm._s(_vm.resultado.msg)+" ")]),(_vm.resultado.detalhes)?_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-virtual-scroll',{attrs:{"items":_vm.resultado.detalhes,"item-height":25,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item',{key:item},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item)+" ")])],1)],1)]}}],null,false,2008930588)})],1):_vm._e()],1),(_vm.resultado.codigo === 'CL02')?_c('div',{staticClass:"d-flex align-center justify-center mb-4"},[_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){_vm.dialogIndeferido = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple-remove-outline")]),_vm._v(" Eliminar candidatos sem nota durante a classificação")],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogResultado = false}}},[_vm._v(" Fechar ")])],1)],1):_vm._e()],1),[_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialogIndeferido),callback:function ($$v) {_vm.dialogIndeferido=$$v},expression:"dialogIndeferido"}},[_c('v-card',[_c('v-feito-title',{staticClass:"primary white--text text-h6 justify-center mb-2"},[_vm._v(" Confirmar Ação ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"pa-5"},[_vm._v(" Esta ação elimina todos os candidatos listados sem notas enem cadastradas, deseja confirmar? ")]),_c('v-divider'),_c('div',{staticClass:"d-flex align-center justify-center mb-4 mt-5",staticStyle:{"gap":"1em"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.eliminaCandidatosSemNotaEnem}},[_vm._v("Sim")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialogIndeferido = false}}},[_vm._v("Não")])],1)],1)],1)],1)]]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }